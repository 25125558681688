import React, { useState } from "react";
import PropTypes from 'prop-types';
import {Form} from "semantic-ui-react";
import {isValidEmail} from "../../utils/utils";

export const CustomEmailInput = ({ value, name, onChange, onFocus, readOnly = false, required = false, label = "Email address", placeholder = "Enter your email", width, onBlur, customError, ...props }) => {
    const [inputError, setInputError] = useState(false);

    const handleFocus = (e) => {
      setInputError(false)
      if(onFocus){
        onFocus(e);
      }
    }

    const handleBlur = (e) => {
      let value = e.target.value;
      // remove blank spaces if necessary
      if(value.includes(" ")) {
        value = e.target.value = e.target.value.trim();
        handleChange(e, { name, value });
      }
      if(!customError){
        validateEmail(value);
      }
      if(onBlur){
        onBlur(e);
      }
    };

    const validateEmail = (email) => {
        let error = null;
        if (required && !email) {
            error = "Email is required";
        } else if (email && !isValidEmail(email)) {
            error = "Invalid email";
        }
        setInputError(error);
    };

    const handleChange = (e, { name, value }) => {
        setInputError(false)
        onChange(e, { name, value });
    };

    return (
      <Form.Field
        error={!!inputError || customError}
        width={width}
        required={required}
      >
        <label>{label}</label>
        <Form.Input
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          className={"customEmailInput"}
          readOnly={readOnly}
          {...props}
        />
        {customError ? (
          <span className="warningRedText smaller">{customError}</span>
        ) : (
          inputError && (
            <span className="warningRedText smaller">{inputError}</span>
          )
        )}
      </Form.Field>
    );
};

CustomEmailInput.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    onBlur: PropTypes.func,
    customError: PropTypes.string,
    onFocus: PropTypes.func
};