
import React from "react";
import {MFAContainer} from './styled';
import {ClickableText, CustomContainer, CustomDivider, CustomGrid} from "../components/custom-common";
import {Button, Header} from "semantic-ui-react";

const MfaSplash = ({skipForNow, configMFA, mfaNotEnabledGracePeriodEnded}) => {
    return (
        <MFAContainer className="splashWrapper" maxWidth={'700px'}>
          <CustomDivider large hidden/>
          <CustomGrid>
            <CustomGrid.Row>
              <CustomGrid.Column>
                <Header as="h2">Set up two-factor authentication</Header>
              </CustomGrid.Column>
            </CustomGrid.Row>
            <CustomGrid.Row>
              <CustomGrid.Column>
                <CustomContainer padded basic className={'bkgNeutral50'}>
                  <Header as="h4">About two-factor authentication</Header>
                  <p className={'small'}>Two-factor authentication is an additional layer of security for your account. In addition to your normal credentials, you'll also need to provide an authentication code when logging in.</p>
                </CustomContainer>
              </CustomGrid.Column>
            </CustomGrid.Row>
            <CustomGrid.Row>
              <CustomGrid.Column>
                <Button primary onClick={configMFA}>Set up two-factor authentication</Button>
              </CustomGrid.Column>
            </CustomGrid.Row>
              {!mfaNotEnabledGracePeriodEnded && (
            <CustomGrid.Row>
              <CustomGrid.Column>
                <ClickableText onClick={skipForNow} small>Skip for now</ClickableText>
              </CustomGrid.Column>
            </CustomGrid.Row>
            )}
          </CustomGrid>
        </MFAContainer>
    )
}

export default MfaSplash;