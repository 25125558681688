import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Message,
  Header
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import PrePortalHeader from "./prePortalHeader";
import { isValidEmail, resolveErrorMessage } from "../utils/utils";
import axios from "axios";
import Footer from "./footer";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {CustomContainer, CustomDivider, CustomEmailInput} from "./custom-common";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      email: null,
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, error: "" });

  handleSubmit = async () => {
    const { email } = this.state;
    if (!isValidEmail(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return;
    }
    try {
      this.setState({ loading: true, error: null });
      await axios.post("/api/employer/v2/requestPasswordReset", { email });
      this.props.history.replace({
        pathname: "/requestSent",
        state: { email: email },
      });
    } catch (e) {
      console.warn(e);
      const error = resolveErrorMessage(
        e,
        "Unable to send reset password request"
      );
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    const { email, loading, error } = this.state;
    const isExpiredLink =
      this.props.location.state && this.props.location.state.resetLink;
    return (
      <React.Fragment>
        <PrePortalHeader />
        <div className="employer-portal-container">
          <Grid
            container
            stackable
            columns={1}
            centered
            style={{ marginTop: 0 }}
          >
            <Grid.Column className={"pageContent"}>
              <Grid centered>
                <Grid.Column style={{ marginTop: "3em" }} width={5}>
                  <CustomDivider hidden />
                  <Header as={"h2"} textAlign={"center"}>
                    {isExpiredLink ? "Link has expired" : "Forgot Password"}
                  </Header>
                  <CustomContainer padded>
                    <p className={"small"}>
                      {isExpiredLink
                        ? `Enter your email address and we'll send you a new link to access ${STRATEGIC_PARTNER.LABEL}—`
                        : "Enter your email address and we'll send you a link to reset your password—"}
                    </p>
                    <Form>
                      <CustomEmailInput
                        value={email || ''}
                        name="email"
                        onChange={(e, { value }) => {
                          this.setState({ email: value, error: false });
                        }}
                        placeholder="Email address"
                        required
                      />
                      <Button
                        primary
                        loading={loading}
                        onClick={this.handleSubmit}
                        disabled={!email}
                      >
                        Send link
                      </Button>
                      <CustomDivider hidden />
                      {error && (
                        <Message negative size="mini">
                          {error}
                        </Message>
                      )}
                    </Form>
                  </CustomContainer>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPassword);