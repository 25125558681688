import React, {Component} from 'react'
import {Button, Form, Message, Modal} from "semantic-ui-react";
import axios from "axios";
import Concierge from "../../concierge";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {ClickableText, CustomDivider, CustomModalHeader, CustomEmailInput} from '../../custom-common';

class EditAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: props.firstName,
            lastName: props.lastName,
            loading: false,
            error: null,
            showConsiergeModal: false
        }
    }

    handleMyInfoFormChange = (event, {value, name}) => {
        this.setState({[name]: value})
    }

    save = async () => {
        const {contactId, onSave, employerId, email} = this.props
        const {firstName, lastName} = this.state
        this.setState({loading: true, error: null})
        try {
            const payload = {contactId, firstName, lastName, employerId, email}
            const result = await axios.post("/api/employer/v2/updateAdminUser", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({loading: false, data: result.data})
            onSave(firstName, lastName)
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to update your contact information"})
        }
    }

    showConciergeModal = (value) => {
        this.setState({showConsiergeModal: value})
    }

    render = () => {
        const {email, open, onCancel} = this.props
        const {firstName, lastName, loading, error, showConsiergeModal} = this.state;
        const disableSubmit = !firstName || !lastName || (firstName === this.props.firstName && lastName === this.props.lastName)
        return (
            <React.Fragment>
                <Modal size={'mini'} onClose={onCancel} open={open}>
                    <CustomModalHeader title={"Edit info"} onClose={onCancel}/>
                    <Modal.Content>
                        <Form loading={loading}>
                            <Form.Input label={"First name"} value={firstName} name={"firstName"} required
                                        onChange={this.handleMyInfoFormChange}/>
                            <Form.Input label={"Last name"} value={lastName} name={"lastName"} required
                                        onChange={this.handleMyInfoFormChange}/>
                            <CustomEmailInput
                                value={email}
                                readOnly
                            />
                            <p className={"small"}>To change your email address, <ClickableText
                                onClick={() => {
                                this.showConciergeModal(true)
                            }} small>send us a message</ClickableText> or call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</p>
                            <CustomDivider hidden/>
                            {error && <Message negative size="mini">{error}</Message>}
                            <Button primary floated={"right"} disabled={disableSubmit} onClick={this.save}>
                                Submit
                            </Button>
                            <Button primary basic floated={"right"} onClick={onCancel}>Cancel</Button>
                        </Form>
                    </Modal.Content>
                    <CustomDivider hidden/>
                </Modal>
                <Concierge open={showConsiergeModal} onManage={this.showConciergeModal} hasDedicatedConcierge/>
            </React.Fragment>
        )
    }
}

export default EditAccountModal;