import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {Button, Form, Header, Icon, Input, Modal} from "semantic-ui-react";
import {
  MFAContainer,
  MFALabel
} from "../styled";
import {CustomContainer, CustomDivider, CustomGrid, CustomModalHeader} from "../../components/custom-common";
import {show as showToast} from "../../actions/toastActions";

const MfaSettingsReminder = ({ setUpMfa }) => {
  return (
    <CustomContainer padded>
      <Header as={"h4"}>
        Set up two-factor authentication
      </Header>
      <CustomDivider small hidden/>
      <CustomContainer basic padded className={"small noVerticalMargin bkgNeutral50"}>
        Two-factor authentication is an additional layer of security for your
        account. In addition to your normal credentials, you'll also need to
        provide an authentication code when logging in.
      </CustomContainer>
      <CustomDivider small hidden/>
      <CustomDivider small hidden/>
      <Button primary onClick={setUpMfa}>
        Set up two-factor authentication
      </Button>
    </CustomContainer>
  );
};

const MfaSettings = ({ mfaEnabled, setLeftNavigation, getMfaSettings, formatDate, generateBackupCodes }) => {
  const [mfaMethods, setMfaMethods] = useState([]);
  const [backupCodeGeneratedTime, setBackupCodeGeneratedTime] = useState(null);
  const [showWarnBackupCodes, setShowWarnBackupCodes] = useState(false);
  const [showReConfigureMethod, setShowReConfigureMethod] = useState(false);
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [mfaSavedCodes, setMfaSavedCodes] = useState(false);
  const [backupCodes, setBackupCodes] = useState(null);
  const [methodToChange, setMethodToChange] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const setUpMfa = () => {
    dispatch(setLeftNavigation(false));
    history.push({ pathname: "/mfa",  setupMfaFromSettings: true});
  };

  const printCodes = () => {

    // Format the date as "MM-DD-YYYY"
    const formattedDate = formatDate(new Date(), null, 'MM-DD-YYYY');

    // Set the filename with the formatted date
    var filename = `Backup codes ${formattedDate}`;

    var divContents = document.getElementById("backUpCodes").innerHTML;
    var a = window.open("", "", "height=250, width=500");
    a.document.write("<html>");
    a.document.write(`<head><title>${filename}</title></head>`);
    a.document.write("<body> <h1>Backup Codes</h1> <br />");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const copyToClipboard = () => {
    const formattedBackupCodes = backupCodes.join('\n');
    navigator.clipboard.writeText(formattedBackupCodes);
    showToastSuccess()
  }

  const regenerateBackupCodes = async () => {
    setShowWarnBackupCodes(false);
    setShowBackupCodes(true);

    await generateBackupCodes()
    .then((response) => {
      setBackupCodes(response.data.backupCodeList);
    })
    .catch((error) => {
      console.log('error: ', error);
    });
  };

  const reConfigureMethod = async () => {
    setShowReConfigureMethod(false);
    history.push({ pathname: '/mfa', changeMethod: methodToChange});
  };


  const getMFAInfo = async () => {
    await getMfaSettings()
      .then(async (response) => {
        setBackupCodeGeneratedTime(response.data.backupCodeGeneratedTime)
        setMfaMethods([response.data]);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const showToastSuccess = () => {
    dispatch(
      showToast({
        type: "success",
        title: "Copy backup codes",
        message: "Backup codes copied to clipboard.",
      })
    );
  };

  useEffect(() => {
    getMFAInfo();
  }, []);

  return mfaEnabled ? (
    <>
      <CustomContainer padded>
        <Header as="h4" textAlign="left">
          Two-factor authentication
        </Header>
        <CustomDivider small hidden/>
        <p className={'small'}>
          Your account is more secure with two-factor authentication.
        </p>
        <CustomContainer basic className={'mfaSettingsEnable'}>
          <Icon name="checkmark" size="small" className={'mfaVerifiedIcon'}/>{" "}
          <p className={'small'}><b>Two-factor authentication is enabled in your account.</b></p>
        </CustomContainer>

        {mfaMethods.map((mfaMethod) => {
          let configuredApp, methodType;
          if (mfaMethod?.totpKey?.active) {
            methodType = "TOTP";
          } else if (mfaMethod?.sms?.active) {
            methodType = "SMS";
          } else if (mfaMethod?.email?.active) {
            methodType = "EMAIL";
          }

          configuredApp = (
              <>
                <div className={"neutral700Text"}>
                  <b>{methodType === "TOTP" ? "Authenticator App" : methodType}</b>
                </div>
                <CustomDivider smaller hidden/>
                <p style={{marginBottom: 8}} className={"smaller neutral700Text"}>
                  <span className={"bold"}>
                    {methodType === "TOTP" ? "Authenticator app" : methodType}
                  </span>{" "}
                    is configured for your account.
                </p>
              </>
          );

          return (
              <>
                <div className={'mfaSectionDivider'} key={methodType}>
                  <CustomGrid>
                    <CustomGrid.Row columns={2}>
                      <CustomGrid.Column floated="left">
                        {configuredApp}
                        <p className={"smaller neutral700Text"}>
                          <Icon
                              name="checkmark"
                              size="small"
                              className="mfaVerifiedIcon"
                          />{" "}
                          Date added {formatDate(mfaMethod.totpKey?.createdTime)}
                        </p>
                      </CustomGrid.Column>
                      <CustomGrid.Column
                          floated="right"
                          className={'mfaFlexEnd'}
                      >
                        <Button basic compact
                                primary
                                onClick={() => {
                                  setShowReConfigureMethod(true);
                                  setMethodToChange(methodType);
                                }}
                        >
                          <span style={{marginRight: '8px'}}>
                            Re-configure
                          </span>
                          <Icon
                              name={"pencil"}
                              floated={"right"}
                          />
                        </Button>
                      </CustomGrid.Column>
                    </CustomGrid.Row>
                  </CustomGrid>
                </div>
              </>
          );
        })}

        <div className={'mfaSectionDivider'}>
          <CustomGrid className={'mfaBackupCodesGrid'}>
            <CustomGrid.Row columns={2}>
              <CustomGrid.Column>
                <div className={"neutral700Text"}>
                  <b>Backup codes</b>
                </div>
                <CustomDivider smaller hidden/>
                <p className={"smaller neutral700Text"}>
                  You will need these backup codes if you ever lose access to
                  your authentication method. Each code may be used only once.<br/>
                  <i>Last generated on {formatDate(backupCodeGeneratedTime)}</i>
                </p>
              </CustomGrid.Column>
              <CustomGrid.Column>
                <Button basic primary compact fluid
                  onClick={() => {
                  setShowWarnBackupCodes(true);
                  }}
                >
                  <span style={{whiteSpace: 'nowrap'}}>
                    <span style={{marginRight: 8}}>
                      Regenerate
                    </span>
                    <Icon
                      name={"redo"}
                      floated={"right"}
                    />
                  </span>
                </Button>
              </CustomGrid.Column>
            </CustomGrid.Row>
          </CustomGrid>
        </div>
      </CustomContainer>

      <Modal
          size={"tiny"} style={{maxWidth: '525px'}}
          onClose={() => {
            setShowWarnBackupCodes(false);
          }}
          open={showWarnBackupCodes}
      >
        <CustomModalHeader
            title={'Are you sure you want to regenerate backup codes?'}
            onClose={() => {
              setShowWarnBackupCodes(false);
            }}/>
        <Modal.Content padded>
          <p className={'small'}>
            Generating new codes will invalidate the previous set you have
            <br /> generated.
          </p>
          <CustomDivider medium hidden/>
          <div style={{textAlign: 'right'}}>
            <Button basic className={"grey"} onClick={() => {
              setShowWarnBackupCodes(false);}}>
              {"Cancel"}
            </Button>
            <Button primary onClick={() => regenerateBackupCodes()}>
              {"Continue"}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
          size={"small"} style={{maxWidth: '570px'}}
          onClose={() => {
            setShowReConfigureMethod(false);
          }}
          open={showReConfigureMethod}
      >
        <CustomModalHeader title={'Are you sure you want to re-configure your authenticator app?'}
                           onClose={() => {
                             setShowReConfigureMethod(false);
                           }}/>
        <Modal.Content>
          <p className={'small'}>
            Re-configuring your authentication will invalidate your previous authentication
            <br/> method.
          </p>
          <CustomDivider hidden medium/>
          <div style={{textAlign: 'right'}}>
            <Button basic className={"grey"} onClick={() => {
              setShowReConfigureMethod(false);
            }}>
              {"Cancel"}
            </Button>
            <Button primary onClick={() => reConfigureMethod()}>
              {"Continue"}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
          size={"small"}
          open={showBackupCodes}
          style={{maxWidth: '650px'}}
      >
        <CustomModalHeader title={'Backup codes'} isCloseVisible={false}/>
        <Modal.Content style={{height: "450px"}} className={"small"}>
          <p className={'small'}>
            You will need these backup codes if you ever lose access to your
            authentication method. Each code may be used only once. Make sure
            you save a copy somewhere safe.
          </p>
          <CustomDivider hidden medium/>

          <MFAContainer className="sectionWrapper" maxWidth={"620px"}>
            <CustomGrid>
              <CustomGrid.Row>
                <CustomGrid.Column>
                  <CustomContainer basic textAlign={'center'}>
                    <CustomGrid.Row>
                      <CustomGrid.Column>
                        <CustomGrid
                            columns={5}
                            id="backUpCodes"
                            className={'bkgNeutral100'}
                            compact={'horizontally'}
                        >
                          {backupCodes?.map((item, index) => {
                            return (
                                <CustomGrid.Column key={index}>
                                  {item}
                                </CustomGrid.Column>
                            );
                          })}
                        </CustomGrid>
                      </CustomGrid.Column>
                    </CustomGrid.Row>
                    <CustomGrid.Row>
                      <CustomGrid.Column>
                        <MFAContainer className={"backupCodeWrapper"}>
                          <div className={'mfaButtonsContainer'}>
                            <Button
                                basic primary
                                onClick={() => {
                                  if(backupCodes){
                                    copyToClipboard();
                                  }
                                }}
                            >
                              Copy
                            </Button>
                            <Button
                                basic primary
                                onClick={() => {
                                  if(backupCodes){
                                    printCodes();
                                  }
                                }}
                            >
                              Print
                            </Button>
                          </div>
                        </MFAContainer>
                      </CustomGrid.Column>
                    </CustomGrid.Row>
                    <CustomGrid.Row>
                      <CustomGrid.Column>
                        <Form className={'mfaJustifyCenter'}>
                          <Form.Field inline>
                            <MFALabel className="labelSavedCodes">
                              <Input
                                  type="checkbox"
                                  name="savedCodes"
                                  id="savedCodes"
                                  onClick={(e) =>
                                      setMfaSavedCodes(e.target.checked)
                                  }
                                  value={!mfaSavedCodes}
                              />
                              I've saved my backup codes
                            </MFALabel>
                          </Form.Field>
                        </Form>
                      </CustomGrid.Column>
                    </CustomGrid.Row>
                  </CustomContainer>
                </CustomGrid.Column>
              </CustomGrid.Row>
            </CustomGrid>
          </MFAContainer>
          <CustomContainer basic className={'mfaCloseBackupContent'} textAlign={'center'}>
            <Button
                primary onClick={() => { setShowBackupCodes(false)}}
                disabled={!mfaSavedCodes}
                className={"closeModaldisabled"}
            >
              {"Close"}
            </Button>
          </CustomContainer>
        </Modal.Content>
      </Modal>
    </>
  ) : (
    <MfaSettingsReminder setUpMfa={setUpMfa} />
  );
};

export default MfaSettings;
