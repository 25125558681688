import React from "react";
import {Button, Form, Header, Input} from "semantic-ui-react";
import {
  MFALabel,
  MFAContainer
} from "../../styled";
import { formatDate } from "../../../utils/common";
import {show as showToast} from "../../../actions/toastActions";
import { useDispatch } from "react-redux";
import {CustomContainer, CustomDivider, CustomGrid} from "../../../components/custom-common";

const BackupCodes = ({ backupCodes, codesAreCopied, setMfaSavedCodes }) => {
  const dispatch = useDispatch();

  const printCodes = () => {

    // Format the date as "dd-mm-yyyy"
    const formattedDate = formatDate(new Date(), null, 'MM-DD-YYYY');

    // Set the filename with the formatted date
    var filename = `Backup codes ${formattedDate}`;

    var divContents = document.getElementById("backUpCodes").innerHTML;
    var a = window.open("", "", "height=250, width=500");
    a.document.write("<html>");
    a.document.write(`<head><title>${filename}</title></head>`);
    a.document.write("<body> <h1>Backup Codes</h1> <br />");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const copyToClipboard = () => {
    const formattedBackupCodes = backupCodes.join('\n');
    navigator.clipboard.writeText(formattedBackupCodes);
    showToastSuccess()
  }

  const showToastSuccess = () => {
    dispatch(showToast({
        type: 'success',
        title: "Copy backup codes",
        message: "Backup codes copied to clipboard.",
    }))
  }

  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"620px"}>
      <CustomGrid>
        <CustomGrid.Row>
          <CustomGrid.Column>
            <Header className={'noVerticalMargin'} as="h2">Backup codes</Header>
            <CustomDivider smaller hidden/>
            <p className="small">
              You will need these backup codes if you ever lose access to your authentication method. Each code may be used only once. Make sure you save a copy somewhere safe.
            </p>
          </CustomGrid.Column>
        </CustomGrid.Row>
        <CustomGrid.Row>
          <CustomGrid.Column>
            <CustomContainer basic textAlign={'center'}>
                <CustomGrid.Row>
                  <CustomGrid.Column>
                    <CustomGrid columns={5} id="backUpCodes" compact={'horizontally'}>
                      {backupCodes?.map((item, index) => {
                        return (
                          <CustomGrid.Column key={index}>{item}</CustomGrid.Column>
                        );
                      })}
                    </CustomGrid>
                  </CustomGrid.Column>
                </CustomGrid.Row>
                <CustomGrid.Row>
                  <CustomGrid.Column>
                    <MFAContainer className={"backupCodeWrapper"}>
                      <div className={'mfaButtonsContainer'}>
                        <Button basic primary
                            onClick={() => {
                              copyToClipboard();
                            }}
                          >
                            Copy
                        </Button>
                        <Button basic primary
                            onClick={() => {
                              printCodes();
                            }}
                        >
                          Print
                        </Button>
                      </div>
                    </MFAContainer>
                  </CustomGrid.Column>
                </CustomGrid.Row>
                <CustomGrid.Row>
                  <CustomGrid.Column>
                    <Form className={'mfaJustifyCenter'}>
                      <Form.Field inline>
                        <MFALabel className="labelSavedCodes">
                          <Input
                            type="checkbox"
                            name="savedCodes"
                            id="savedCodes"
                            onClick={(e) => setMfaSavedCodes(e.target.checked)}
                            style={{ marginRight: 10 }}
                            value={!codesAreCopied}
                          />
                          I've saved my backup codes
                        </MFALabel>
                      </Form.Field>
                    </Form>
                  </CustomGrid.Column>
                </CustomGrid.Row>
            </CustomContainer>
          </CustomGrid.Column>
        </CustomGrid.Row>
      </CustomGrid>
    </MFAContainer>
    </>
  );
};

export default BackupCodes;
