import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { store } from "./store";
import CreatePassword from "./components/createPassword";
import { Provider } from "react-redux";
import TermsOfService from "./components/termsOfService";
import Login from './components/pages/login';
import Portal from "./components/portal/portal";
import ForgotPassword from "./components/forgotPassword";
import AccountCreation from "./components/accountCreation/accountCreation";
import RequestSent from "./components/requestSent";
import ResetPassword from "./components/resetPassword";
import ConfirmUsername from "./components/confirmUsername";
import Config from "./components/config";
import PrivateRoute from "./components/privateRoute";
import ErrorBoundary from "./components/errorBoundary";
import PMLinkPage from './components/pmlink/pmLinkPage';
import MfaAuth from "./components/mfaAuth"
import { axiosInit } from './utils/axios';
import {
  MFA_ROUTE
} from "./utils/common";
import Toasts from "./components/toasts/toasts";
import './app.css';

import handleRumSetup from "./datadog/rum";

handleRumSetup();

axiosInit();

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
        <Config>
          <Switch>
            <Route exact path="/login" render={() => <Login />} />
            <Route exact path="/setPassword" render={() => <CreatePassword nextRoute={"/login"} />} />
            <Route exact path="/tos" render={() => <TermsOfService />} />
            <Route exact path="/addPaymentMethodWithLinkToken" render={() => <PMLinkPage />} />
            <PrivateRoute path={MFA_ROUTE} component={MfaAuth}/>
            <PrivateRoute path="/accountCreation" component={AccountCreation} />
            <PrivateRoute path="/portal" component={Portal} />
            <Route exact path="/forgotPassword" render={ForgotPassword} />
            <Route exact path="/requestSent" render={RequestSent} />
            <Route exact path="/resetPassword" render={() => <ResetPassword />} />
            <Route exact path="/confirmUsername" render={() => <ConfirmUsername /> }/>
            <Redirect to="/login" />
          </Switch>
        </Config>
      </BrowserRouter>
      <Toasts />
    </Provider>
  </ErrorBoundary>
)

export default App
