import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  MFAContainer,
} from "../styled";

import {
  validateMfa,
  validateBackupCode,
  setNewToken,
} from "../../actions/authActions";
import STRATEGIC_PARTNER  from "../../constants/strategicPartners";

import {ClickableText, CustomContainer, CustomDivider, CustomGrid} from "../../components/custom-common";
import {Button, Checkbox, Form, Header, Input, Message} from "semantic-ui-react";


const MINIMUM_ATTEMPTS = 0;
const LEFT_ATTEMPTS_FOR_WARN = 3;

const MFACode = ({
  userName,
  mfaToken,
  redirectPath,
  setBackupCodes,
  history,
  dispatch,
  useBackUpCodes,
  redirectToLogin,
  rememberDeviceDays
}) => {
  const [mfaCode, setMfaCode] = useState("");
  const [mfaBackupCode, setMfaBackupCode] = useState("");
  const [validationErrors, setValidationErrors] = useState(false);
  const [validateMfaCodeError, setValidateMfaCodeError] = useState("");
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [remainingTotpAttempts, setRemainingTotpAttempts] = useState(null);
  const [remainingBackupAttempts, setRemainingBackupAttempts] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [remember, setRemember] = useState(false);

  //Input Backup code handler and validation
  const codeHandler = (e) => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    e.currentTarget.value.length > 0 ? setDisableBtn(false) : setDisableBtn(true)

    if (e.currentTarget.name === "mfaCode") {
      let sanitizedValue = e.currentTarget.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      if (sanitizedValue.length > 6 || isNaN(sanitizedValue)) return;
      setMfaCode(sanitizedValue);
    } else {
      if (e.currentTarget.value.length > 10) return;

      setMfaBackupCode(e.currentTarget.value);
    }
  };


  //Submit code to mfa/login
  const submitCode = async () => {
    setValidateMfaCodeError("");
    setIsMfaProcessing(true);

    if (!useBackUpCodes) {
      if (mfaCode.length <= 5 || isNaN(mfaCode) || mfaCode === "") {
        setValidationErrors(true);
        setIsMfaProcessing(false);
        return false;
      }

      await validateMfa(userName, mfaCode, mfaToken, remember)
        .then(async (response) => {
          dispatch(setNewToken(response.data));
          //dispatch(setInitialData());
          history.push({ pathname: redirectPath });
        })
        .catch((error) => {
          const { errorMessage, remainingTotpCodeAttempts, remainingBackupCodeAttempts } =
            error?.response?.data;
          setIsMfaProcessing(false);
          setValidateMfaCodeError(errorMessage);
          if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS || remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
            redirectToLogin(true);
          }
          setRemainingTotpAttempts(remainingTotpCodeAttempts);
        });
    } else {
      if (mfaBackupCode.length < 10) {
        setValidationErrors(true);
        setIsMfaProcessing(false);
        return false;
      }

      await validateBackupCode(mfaBackupCode, mfaToken)
        .then(async (response) => {
          dispatch(setNewToken(response.data));
          //dispatch(setInitialData());
          history.push({ pathname: redirectPath });
        })
        .catch((error) => {
          const { errorMessage, remainingBackupCodeAttempts, remainingTotpCodeAttempts } =
            error.response.data;
          setIsMfaProcessing(false);
          setValidateMfaCodeError(errorMessage);
          if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS || remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
            redirectToLogin(true);
          }
          setRemainingBackupAttempts(remainingBackupCodeAttempts);
        });
    }
  };

  const changeToBackupCode = () => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    setRemainingTotpAttempts(null);
    setRemainingBackupAttempts(null);
    setBackupCodes(true);
    setDisableBtn(true);
  };
  return (
    <>
      <p className={"small"}>
        {!useBackUpCodes
          ? "Enter the 6-digit authentication code from your authenticator app to continue—"
          : "Enter one of your backup codes to access your account—"}
      </p>

      {(remainingTotpAttempts || remainingBackupAttempts) &&
        (remainingTotpAttempts <= LEFT_ATTEMPTS_FOR_WARN ||
          (remainingBackupAttempts <= LEFT_ATTEMPTS_FOR_WARN && useBackUpCodes)) && (
            <Message negative className={"invalidAttempt smaller"}>
              <b>
                {!useBackUpCodes
                  ? remainingTotpAttempts
                  : remainingBackupAttempts}{" "}
                invalid attempt(s) remaining!
              </b>{" "}
              After your <br/>account will be locked for security purposes.{" "}
              {useBackUpCodes}
            </Message>
      )}
      <Form>
      <Form.Field>
        <MFAContainer className="changeMethodField">
          <Form.Input fluid className={'noVerticalMargin'}
            label={!useBackUpCodes ? "6-digit authentication code" : "10-digit backup code"}
            type="text"
            name={!useBackUpCodes ? "mfaCode" : "backupCode"}
            id={!useBackUpCodes ? "mfaCode" : "backupCode"}
            onChange={(e) => codeHandler(e)}
            value={!useBackUpCodes ? mfaCode : mfaBackupCode}
            maxLength={!useBackUpCodes ? "6" : "10"}
            required
            error={validationErrors}
            disabled={isMfaProcessing}
          />
          {validationErrors && <span className={'warningRedText smaller'}>
            {!useBackUpCodes ? "Enter a 6-digit authentication code" : "Enter a 10-digit backup code"}
          </span>
          }
          {validateMfaCodeError && (
              <span className={'warningRedText smaller'}>
              Invalid code, please try again.
            </span>
          )}
        </MFAContainer>
        {!useBackUpCodes && (
            <MFAContainer className="changeMethodField">
          <Checkbox
            label={`Remember me on this device for ${rememberDeviceDays} days`}
            name={"mfaRemember"}
            id={"mfaRemember"}
            onChange={(e) => setRemember(!remember)}
            /* value={!useBackUpCodes ? mfaRemember : backupRemember} */
          />

        </MFAContainer>
        )}
        <Button primary fluid
          loading={isMfaProcessing}
          disabled={isMfaProcessing || disableBtn}
          onClick={() => {
            submitCode();
          }}
        >
          Submit
        </Button>
      </Form.Field>
      </Form>
      {!useBackUpCodes && (
          <>
            <CustomDivider small hidden/>
            <ClickableText
            onClick={() => {
              if (!isMfaProcessing) {
                changeToBackupCode();
              }
            }}
            small
          >
            Or, use a backup code
          </ClickableText>
        </>
        )}
    </>
  );
};


const ValidateMFA = (props) => {
  const [useBackUpCodes, setBackupCodes] = useState(false);
  const { userName, mfaToken, redirectPath, loginPath, rememberDeviceDays } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToLogin = (isLocked) => {
    history.push({ pathname: loginPath, isLocked});
  };
  return (
    <>
      <MFAContainer
        style={{ paddingTop: "125px", textAlign: "center" }}
      >
        <Header as="h2" textAlign="center">
          Authentication code required
        </Header>
        <CustomGrid fitted column={1} className={'mfaAuthCodeContainer'}>
          <CustomContainer padded textAlign={'left'}>
            <MFACode
                userName={userName}
                mfaToken={mfaToken}
                redirectPath={redirectPath}
                setBackupCodes={setBackupCodes}
                history={history}
                dispatch={dispatch}
                useBackUpCodes={useBackUpCodes}
                redirectToLogin={redirectToLogin}
                rememberDeviceDays={rememberDeviceDays}
            />
          </CustomContainer>
          <div className="backupCodeText">
            {useBackUpCodes && (
                <>
                  <p className={'small noVerticalMargin'}>
                    Still having trouble? Contact us at{" "}
                    <a href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}</a> or
                    call (888)
                    290-1153.
                  </p>
                  <CustomDivider small hidden/>
                  <CustomDivider small hidden/>
                </>
            )}
            <ClickableText
                  onClick={() => redirectToLogin()}
                  small
              >
                Return to login screen
            </ClickableText>
          </div>
        </CustomGrid>
      </MFAContainer>
    </>
);
};

export default ValidateMFA;
