import React from "react";

import {
  MFAContainer
} from "../../../styled";
import {Form, Header, Icon} from "semantic-ui-react";
import {CustomDivider, CustomGrid} from "../../../../components/custom-common";


const VerifyRenderItem = ({code, validationErrors, isDisabled, codeHandler, validateForm }) => {
  return (
    <>
      <Form.Field className={'mfaVerifyCode noVerticalMargin'} error={validationErrors}>
        <Form.Input className={'noVerticalMargin'}
          label={'6-digit authentication code'}
          type="text"
          name="authenticatorCode"
          id="authenticatorCode"
          value={code}
          onChange={(e) => codeHandler(e)}
          maxLength="6"
          required
          disabled={isDisabled}
          onBlur={(e) => validateForm(code)}
        />
      </Form.Field>
      {isDisabled &&
      (
          <>
            <p className="mfaVerified small">
              <Icon name="checkmark" size="small"/>
              Verified
            </p>
          </>
      )}
    </>
  );
};

const AuthenticatorVerify = ({
  codeHandler,
  validationErrors,
  code,
  validateMfaCode,
  validateMfaCodeError,
  loading,
  mfaEnabled,
  changeMethodSettings,
  isMethodChanged,
  validateForm
}) => {
  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"497px"}>
      <CustomGrid>
        <CustomGrid.Row>
          <CustomGrid.Column>
            <Header as="h2" className={'noVerticalMargin'}>Enter authentication code</Header>
            <CustomDivider smaller hidden/>
            <p className={'small'}>
              Enter the 6-digit code generated by your authenticator app to verify setup—
            </p>
          </CustomGrid.Column>
        </CustomGrid.Row>
        <CustomDivider smaller hidden/>
        <CustomGrid.Row>
          <CustomGrid.Column>
            <Form>
              <Form.Group grouped>
                {!changeMethodSettings && (
                  <VerifyRenderItem code={code} validationErrors={validationErrors || validateMfaCodeError} isDisabled={mfaEnabled} loading={loading} codeHandler={codeHandler} validateMfaCode={validateMfaCode} validateForm={validateForm}/>
                )}
                {changeMethodSettings && (
                  <VerifyRenderItem code={code} validationErrors={validationErrors || validateMfaCodeError} isDisabled={isMethodChanged} loading={loading} codeHandler={codeHandler} validateMfaCode={validateMfaCode} validateForm={validateForm}/>
                )}
                {(validationErrors && !validateMfaCodeError) && (
                  <p className={'warningRedText smaller'}>Enter a 6-digit authentication code</p>
                )}
                {validateMfaCodeError && (
                  <p className={'warningRedText smaller'}>{validateMfaCodeError}</p>
                )}
              </Form.Group>
            </Form>
          </CustomGrid.Column>
        </CustomGrid.Row>
        </CustomGrid>
      </MFAContainer>
    </>
  );
};

export default AuthenticatorVerify;
