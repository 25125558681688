import React, { useState, useEffect } from "react";
import {
    MFAContainer,
} from "../styled";
import {CustomDivider, CustomGrid} from "../../components/custom-common";
import {Button, Form, Header} from "semantic-ui-react";

const ValidateChangeMFA = ({removeMethod, validateMfaCodeError, loading}) => {
    const [password, setPassword] = useState(undefined);
    const [error, setError] = useState(validateMfaCodeError)
    const [disableBtn, setDisableBtn] = useState(true);

    const handleChange = (e) => {
        if (e.target.value.length > 0) {
          setDisableBtn(false); setError(null)
        } else {
          setDisableBtn(true)
        };
        setPassword(e.target.value)
    }

    useEffect(() => {
      setError(validateMfaCodeError);
    }, [validateMfaCodeError])

    return (
      <MFAContainer maxWidth={"456px"} className= {"bkgNeutral50 mfaPasswordPage"}>
        <Header as="h2" textAlign="center">
            Enter password
        </Header>
        <CustomDivider small hidden/>
        <CustomGrid>
          <CustomGrid.Row columns={2}>
            <CustomGrid.Column width={12}>
              <MFAContainer className="verifyMethodChange" maxWidth={"308px"}>
                  <p className={'small'}>
                      Enter your password to re-configure two-factor authentication
                      app—
                  </p>
                  <Form>
                    <Form.Field>
                      <Form.Input fluid
                          label={'Password'}
                          type="password"
                          name={"changeMFAMethod"}
                          id={"changeMFAMethod"}
                          onChange={(e) => handleChange(e)}
                          value={password}
                          required
                          disabled={loading}
                          className={"verifyInput noVerticalMargin"}
                      />
                          {error && <p className={'smaller warningRedText'}> {error}</p>}

                    </Form.Field>
                  </Form>
                  <CustomDivider small hidden/>
                  <Button primary
                    loading={loading}
                    disabled={loading || disableBtn}
                    onClick={() => {
                        removeMethod(password)
                    }}
                    >
                    Submit
                  </Button>
              </MFAContainer>
            </CustomGrid.Column>
          </CustomGrid.Row>
        </CustomGrid>
      </MFAContainer>
    );
}

export default ValidateChangeMFA