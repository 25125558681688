import styled from "styled-components";

export const MFAContainer = styled.div`
  background-color: #fff;
  margin: 65px auto;
  overflow: hidden;
  padding-bottom: 80px;
  text-align: left;
  &.backupCodeWrapper {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin-top: 48px;
   padding-bottom: 24px;
   margin-bottom: 0px;
  }
  &.sectionWrapper{
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 15px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &.splashWrapper{
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 10px;
    margin: 64px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &.successWrapper {
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 10px;
    margin: 101px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &.mfaPasswordPage{
    padding-top: 125px;
    text-align: center;
    height: 92vh;
  }
  &.verifyMethodChange{
    max-width: ${(props) => props.maxWidth}!important;
    padding: 24px 29px;
    text-align: left;
    margin-top: 0!important;
    border-radius: 0px 0px 8px 8px;
    & > .container {
      margin-top: 0;
      padding-bottom: 10px;
    }
  }
  &.changeMethodField{
    margin-top: 0px;
    padding-bottom: 10px;
    margin-bottom: 0!important;
  }
`;

export const MFALabel = styled.label`
      font-weight: 700 !important;
      font-size: 14px !important;
      display: flex !important;
      align-items: center;
      &.labelSavedCodes {
        font-weight: 400 !important;
        & > .input {
          margin-right: 10px!important;
        }
      }
`;
