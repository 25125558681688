import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {setLeftNavigation} from "../../actions/navigationActions";
import {ClickableText, CustomContainer} from "../../components/custom-common";
import {Icon} from "semantic-ui-react";

const MfaReminder = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const setUpMfa = () => {
        dispatch(setLeftNavigation(false));
        history.push({ pathname: '/mfa'});
    }

    return(
        <CustomContainer basic className={"mfaReminder"}>
            <p className={"small"} style={{marginBottom: 0}}><b>Two-factor needs to be setup</b></p>
            <p className={"smaller neutral400Text"}>Add more security to your account with two-factor authentication.</p>
            <ClickableText onClick={setUpMfa}>
                Set it up now <Icon name={'long arrow alternate right'} />
            </ClickableText>
        </CustomContainer>
    )
}

export default MfaReminder