
import React from 'react';
import Stepper from './stepper';
import MethodSelector from '../methods/methodSelector';

//Authenticator
import AuthenticatorConfig from '../methods/authenticator/config';
import AuthenticatorVerify from '../methods/authenticator/verify'

//Email
import EmailConfig from '../methods/email/config';
import EmailVerify from '../methods/email/verify'

//SMS
import SmsConfig from '../methods/sms/config';
import SmsVerify from '../methods/sms/verify'

//Backup Codes
import BackupCodes from '../backup'

//Success Page
import SuccessMFASetup from '../success'
import {CustomGrid} from "../../../components/custom-common";

const Steps = ({methodType, currentStep, selectMethodHandler, codeHandler, validationErrors, mfaSetupParams, code, setMfaSavedCodes, mfaCodeAreSaved, mfaEnabled, validateMfaCode, validateMfaCodeError, loading, backupCodes, continueToPortal, mfaSetup, STEPS_CONFIG_INIT, changeMethodSettings, isMethodChanged, validateForm}) => {
    return (

        <>
            <CustomGrid centered>
                    {mfaSetup && (
                    <CustomGrid.Row>
                        <CustomGrid.Column>
                            {mfaSetup && (<Stepper step={currentStep} steps={STEPS_CONFIG_INIT} changeMethodSettings={changeMethodSettings}/>)}
                        </CustomGrid.Column>
                    </CustomGrid.Row>
                    )}
                    <CustomGrid.Row>
                        <CustomGrid.Column>
                        {(!mfaEnabled && currentStep === 0) && (<MethodSelector methodType={methodType} selectMethodHandler={selectMethodHandler}/>)}
                        {((!mfaEnabled && currentStep === 1) || (changeMethodSettings && currentStep === 0)) && (
                                <>
                                    {methodType === 'TOTP' && <AuthenticatorConfig mfaSetupParams={mfaSetupParams}/>}
                                    {methodType === 'EMAIL' && (<EmailConfig />)}
                                    {methodType === 'SMS' && (<SmsConfig />)}
                                </>
                            )

                        }
                        {((currentStep === 2 && !changeMethodSettings) || (changeMethodSettings && currentStep === 1)) &&
                         (
                            <>
                                {methodType === 'TOTP' && <AuthenticatorVerify codeHandler={codeHandler} validationErrors={validationErrors} code={code} validateMfaCodeError={validateMfaCodeError} validateMfaCode={validateMfaCode} loading={loading} mfaEnabled={mfaEnabled} validateForm={validateForm} changeMethodSettings={changeMethodSettings} isMethodChanged={isMethodChanged}/>}
                                {methodType === 'EMAIL' && <EmailVerify codeHandler={codeHandler} validationErrors={validationErrors} />}
                                {methodType === 'SMS' && <SmsVerify codeHandler={codeHandler} validationErrors={validationErrors} />}
                            </>
                         )
                        }
                        {((currentStep === 3 && !changeMethodSettings && !isMethodChanged) || (changeMethodSettings && currentStep === 2 )) && (<BackupCodes savedCodes={mfaCodeAreSaved} setMfaSavedCodes={setMfaSavedCodes} backupCodes={backupCodes} />)}
                        {((mfaEnabled && currentStep === 4) || (isMethodChanged && currentStep === 3 )) && (<SuccessMFASetup continueToPortal={continueToPortal} />)}
                        </CustomGrid.Column>
                    </CustomGrid.Row>
            </CustomGrid>
        </>
    )
}

export default Steps;