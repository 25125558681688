import React from 'react';
import {Form, Header, Input} from 'semantic-ui-react'
import {
    MFAContainer,
  } from "../../styled";
import {CustomContainer, CustomDivider, CustomGrid} from "../../../components/custom-common";


const MethodItem = ({title, disabled}) => {
    return (
        <CustomContainer className={'mfaMethodContainer'}>
            <div>
                <p className={`mfaMethodTitle ${disabled ? 'mfaMethodTitleDisabled' : ''}`}>{title}</p>
                {!disabled &&
                    <p className={'small'}>Use an authenticator app to generate authentication codes.</p>
                }
            </div>
            {disabled && <span className={'mfaMethodComingSoon'}>Coming soon</span>}
        </CustomContainer>
    )
}

const MethodSelector = ({methodType, selectMethodHandler}) => {
    return (
        <>
        <MFAContainer className="sectionWrapper" maxWidth={"530px"}>
            <CustomGrid centered>
                <CustomGrid.Row>
                    <CustomGrid.Column>
                        <Header className={'noVerticalMargin'} as="h2">Select your method</Header>
                        <CustomDivider smaller hidden/>
                        <p className={'noVerticalMargin'}>Choose one of the two-factor authentication methods below—</p>
                        <CustomDivider smaller hidden/>
                    </CustomGrid.Column>
                </CustomGrid.Row>
                <CustomGrid.Row>
                    <CustomGrid.Column>
                        <Form>
                            <Form.Group grouped>
                                <CustomGrid>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column>
                                            <Form.Field inline className={'mfaMethodField'} onClick={() => selectMethodHandler('TOTP')}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id="authenticator"
                                                        checked={methodType === 'TOTP'}
                                                        />
                                                <MethodItem title={"Authenticator app"} />
                                            </Form.Field>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column>
                                            <div className={'mfaMethodsOrDivider'}><b>OR</b></div>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column>
                                            <Form.Field inline className={'mfaMethodFieldDisabled'} onClick={null}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='email'
                                                        checked={methodType === 'EMAIL'}
                                                        disabled
                                                        />
                                                <MethodItem title={"Email"} disabled={true} />
                                            </Form.Field>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column>
                                            <Form.Field inline className="mfaMethodFieldDisabled"  onClick={null}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='sms'
                                                        checked={methodType === 'SMS'}
                                                        disabled />
                                                <MethodItem title={"Text message (SMS)"} disabled={true} />
                                            </Form.Field>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                </CustomGrid>
                            </Form.Group>
                        </Form>
                    </CustomGrid.Column>
                </CustomGrid.Row>
            </CustomGrid>
        </MFAContainer>
        </>
    )
}

export default MethodSelector;