import React from "react";
import {
  MFAContainer
} from "../../styled";
import IMAGE from "../../../constants/images";
import {CustomDivider, CustomGrid, S3Image} from "../../../components/custom-common";
import {Button, Header} from "semantic-ui-react";

const SuccessMFASetup = ({ continueToPortal }) => {
  return (
    <>
    <MFAContainer className="successWrapper" maxWidth={"590px"}>
      <CustomGrid>
        <CustomGrid.Row>
          <CustomGrid.Column textAlign={'center'}>
            <S3Image {...IMAGE.CHECK_80PX}/>
            <CustomDivider small hidden/>
            <Header as="h1">Success</Header>
            <p className={'noVerticalMargin small'}>
              Two-factor authentication has been enabled for your account.
            </p>
          </CustomGrid.Column>
        </CustomGrid.Row>
        <CustomGrid.Row compact={'vertically'}>
          <CustomGrid.Column textAlign={'center'}>
            <Button primary onClick={() => continueToPortal()}>
              Continue
            </Button>
          </CustomGrid.Column>
        </CustomGrid.Row>
      </CustomGrid>
    </MFAContainer>
    </>
  );
};

export default SuccessMFASetup;
