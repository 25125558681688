import React from "react";
import QRCode from "react-qr-code";
import {
  MFAContainer,
} from "../../../styled";
import {CustomContainer, CustomDivider, CustomGrid} from "../../../../components/custom-common";
import {Header, List} from "semantic-ui-react";

const AuthenticatorConfig = ({ mfaSetupParams }) => {
  const {issuer, key, username } = mfaSetupParams?.totp;

  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"633px"}>
      <CustomGrid>
        <CustomGrid.Row>
          <CustomGrid.Column>
            <Header className={'noVerticalMargin'} as="h2">Configure two-factor authentication</Header>
            <CustomDivider smaller hidden/>
            <p className="neutral700Text small">Follow the instructions to complete setup—</p>
          </CustomGrid.Column>
        </CustomGrid.Row>
        <CustomDivider smaller hidden/>
        <CustomGrid.Row columns={2}>
          <CustomGrid.Column width={7}>
            <List ordered className={'mfaConfigList'}>
              <List.Item>
                <List.Content className={'small'}>
                  Install or open your authenticator app (e.g. Google Authenticator, Duo Mobile, Authenticator).
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content className={'small'}>
                  Scan the image to the right using your device's camera or manually enter your secret key.
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content className={'small'}>
                  If the app displays a 6-digit code, click Next.
                </List.Content>
              </List.Item>
            </List>
          </CustomGrid.Column>
          <CustomGrid.Column width={5}>
            <CustomContainer className={'mfaCustomBoxQR'} textAlign="center">
              {mfaSetupParams.totp && (
              <QRCode
              value={encodeURI(`otpauth://totp/${issuer}:${username}?secret=${key}&issuer=${issuer}`)}
                style={{
                  height: "150px",
                  maxWidth: "100%",
                  width: "150px",
                  marginBottom: "24px",
                }}
              />
              )}
              <p className="small">Secret key </p>
              <div style={{wordWrap: 'break-word'}}>
                <b>{key}</b>
              </div>
            </CustomContainer>
          </CustomGrid.Column>
        </CustomGrid.Row>
      </CustomGrid>
      </MFAContainer>
    </>
  );
};

export default AuthenticatorConfig;
